<script>
export let space = '2rem';
</script>

<style lang="scss">
.GradientLine {
  border: 0;
  height: 1px;
  width: 100%;

  margin-top: var(--space);

  background:         linear-gradient($theme_gradient);
  background:      -o-linear-gradient($theme_gradient);
  background:    -moz-linear-gradient($theme_gradient);
  background: -webkit-linear-gradient($theme_gradient);
}
</style>

<hr class="GradientLine" style="--space: {space}">
